<template>
  <div
    v-if="type == 'banner' && promotionTip"
    class="cart_item-header without-promoGoods promo-group"
    :class="[`j-promotion-header-${promotionContent.id}`]"
    :type-id="promotionContent.typeId"
  >
    <!-- 横幅类型 -->
    <slot></slot>
    <section
      v-if="promotionTip"
      v-enterkey
      class="cart_item-banner"
      :class="{ 
        clickable: (promotionContent.action == 1 || showFlashSaleEnter) && !isBatchActivePage,
        'mini-cart': page == 'bag' 
      }"
      tabindex="0"
      :role="isCanClick ? 'button' : 'presentation'"
      @click="clickPick"
    >
      <p
        class="cart_item-tip"
        :class="{'cart-red-dark': changeColor}"
      >
        <span v-html="promotionTip"></span>
        <ClientOnly>
          <count-down-time
            v-if="integrityPromotionInfo.is_count_down == 1"
            :time-stamp="integrityPromotionInfo.end_time"
          />
        </ClientOnly>
      </p>
      <sui_icon_more_right_14px
        v-if="(promotionContent.action == 1 || showFlashSaleEnter) && !isBatchActivePage && promotionContent.typeId != 11"
        size="16px"
        :is-rotate="cssRight"
      />
    </section>
  </div>
  <div
    v-else-if="promotionTip"
    class="cart_item-header promo-group cart_item-header__gift"
    :class="`j-promotion-header-${promotionContent.id}`"
    :type-id="promotionContent.typeId"
  >
    <!-- 标题 + 提示语 -->
    <div class="header-content">
      <slot></slot>
      <section class="cart-item__header-section">
        <div style="width: 100%">
          <div
            class="cart_item-title"
            tabindex="0"
            :aria-label="promotionContent.title + ',' + promotionTip"
          >
            <span :class="{'promotion-title':page == 'bag'}">{{ promotionContent.title }}</span>
            <a
              v-if="page == 'bag' && promotionContent.action > 0"
              v-enterkey
              class="cart_item-action"
              tabindex="0"
              role="button"
              @click="clickPick"
            >
              <span>{{ handleText }}</span>
              <sui_icon_more_right_14px size="16px" />
            </a>
          </div>
          <p
            class="cart_item-desc"
            v-html="promotionTip"
          >
          </p>
          <ClientOnly>
            <count-down-time
              v-if="integrityPromotionInfo.is_count_down == 1"
              class="c-count__down-time"
              :time-stamp="integrityPromotionInfo.end_time"
              show-type="inline-promotion"
              :show-end-tips="true"
            />
          </ClientOnly>
        </div>
        <template v-if="promotionContent.action > 0 && !isBatchActivePage">
          <s-button
            v-if="page !== 'bag'"
            v-enterkey
            :type="['primary','H28PX']"
            @click="clickPick"
          >
            {{ handleText }}
          </s-button>
        </template>
      </section>
    </div>
    <GiftList
      v-if="showFullGiftsList"
      :gift-list="getGiftLift"
      :promotion-id="promotionId"
      :promotion-type="promotionTypeId"
      :fulfill-status="fulfillStatus"
      :type="1"
      :is-show="showFullGiftsList"
      @handle-view-all="clickPick(false)"
    />
  </div>
</template>

<script>
import countDownTime from 'public/src/pages/cart_new/components/CartCountDown.vue'
import { daEventCenter } from '../../../../services/eventCenter'
import { PromotionUtils } from '../../../common/promotion/utils'
import { handleOrderOrSingleIsMeetOrOver } from 'public/src/pages/cart_new/utils/index.js'
import GiftList from './giftList/GiftList.vue'
import { isObject } from '@shein/common-function'
import { sui_icon_more_right_14px } from '@shein-aidc/icon-vue3'

export default {
  name: 'PromotionGroupHeader',
  components: {
    countDownTime,
    GiftList,
    sui_icon_more_right_14px
  },
  props: {
    page: {
      type: String,
      default: ''
    },
    integrityPromotionInfo: {
      type: Object,
      default: function () {
        return {}
      }
    },
    isBatchActivePage: {
      type: Boolean,
      default: false
    },
    abtParams: {
      type: Object,
      default: function () {
        return {}
      }
    },
    language: {
      type: Object,
      default: function () {
        return {}
      }
    },
    cartPromotionMap: {
      type: Object,
      default: function () {
        return {}
      }
    },
    tip: {
      type: [String, Object],
      default: ''
    },
    cssRight: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isCanClick(){
      return true
    },
    promotionContent(){
      return this.integrityPromotionInfo.content || {}
    },
    type () {
      const typeId = this.promotionContent.typeId
      const BannerStyleTypeIds = [1, 9, 10, 11, 14, 15, 16, 17, 18, 19, 24, 25] // 横幅类型的促销活动
      let result = 'common'
      if (BannerStyleTypeIds.includes(Number(typeId))) result = 'banner'
      return result
    },
    promotionTip() {
      const  tip  = this.page !== 'bag' ? this.integrityPromotionInfo.content?.tip || '' : this.tip
      return isObject(tip) ? tip[this.isChecked ? 1 : 0] : tip
    },
    showFlashSaleEnter() {
      const typeId = this.promotionContent.typeId
      const FlashSaleTypeIds = ['10']

      if (FlashSaleTypeIds.includes('' + typeId)) {
        /**
         * PromotionIcon 推全 ShowPromotionIcon
         */
        // return this.abtParams?.PromotionIcon?.param == 'ShowPromotionIcon' ? true : false
        return true
      } else {
        return false
      }
    },
    // 返券活动的action只有0满足不展示、 1不满足展示add
    handleText () {
      if (this.promotionContent.action == 1) {
        return this.language.SHEIN_KEY_PC_15215
      } else if (this.promotionContent.action == 2) {
        const isPromoGoods = this.integrityPromotionInfo.list.some(item => item.product?.product_promotion_info?.some(p => (p.promotion_product_type == '1' || p.promotion_product_type == '2')))
        if (isPromoGoods) {
          return this.language.SHEIN_KEY_PC_15969
        } else {
          return this.language.SHEIN_KEY_PC_15968
        }
      } else {
        return ''
      }
    },
    changeColor(){
      let LimitIds = []
      let promotionGroupList = this.integrityPromotionInfo.list || []
      promotionGroupList.forEach( cartItem => {
        let result = handleOrderOrSingleIsMeetOrOver(cartItem, this.integrityPromotionInfo, true)
        if(result && !LimitIds.includes(cartItem.promotionGroupId)){
          LimitIds.push(cartItem.promotionGroupId)
        }
      })

      if( LimitIds.length ){
        return true
      }
      return false
    },
    showFullGiftsList(){
      return !this.isPicked && this.getGiftLift.length && !this.isBatchActivePage && this.page !== 'bag'
    },
    // 是否选择了赠品
    isPicked() {
      const { id } = this.integrityPromotionInfo.content || {}
      const value = this.integrityPromotionInfo.list.some(item => item.product?.product_promotion_info?.some(p => [1, 2].includes(+p.promotion_product_type) && p.promotion_id == id))
      return value
    },
    // 选择赠品是否有效
    isChecked() {
      const { id } = this.integrityPromotionInfo.content || {}
      const value = this.integrityPromotionInfo.list.some(item => item.product?.product_promotion_info?.some(p => [1, 2].includes(+p.promotion_product_type) && p.promotion_id == id && item.is_checked))
      return value
    },
    getGiftLift() {
      return this.promotionContent.showFullGiftList || []
    },
    promotionId() {
      return this.integrityPromotionInfo.promotion_id || ''
    },
    promotionTypeId() {
      return this.integrityPromotionInfo.type_id || 4
    },
    fulfillStatus() {
      return this.promotionContent.fulfillStatus || 0
    }
  },
  inject: ['openAddItem'],
  emits: ['handleAddOn'],
  mounted () { 
    const { id, typeId } = this.promotionContent
    if (this.cartPromotionMap?.[id]) {
      daEventCenter.triggerNotice({
        daId: '1-7-5-1',
        extraData: {
          id,
          typeId,
          isFullPromotion: this.cartPromotionMap[id].isFullPromotion,
          is_brand_product: this.integrityPromotionInfo?.goods?.type,
          is_member_gift: 0,
          is_all_store: this.integrityPromotionInfo?.all_store_flag,
          store_id: this.integrityPromotionInfo?.store_id,
        }
      })
    }
  },
  methods: {
    clickPick: async function (isAnalysis = true){
      if (this.isBatchActivePage) return
      const { action, id: promotionId, typeId  } = this.promotionContent
      const promotionItem = this.integrityPromotionInfo

      const addGoodToReach = promotionItem.goods?.sc_id && action == 1
      promotionItem.scId = promotionItem.goods?.sc_id || ''
      const buyGiftPick = typeId == 2 && (action == 2)
      const addBuyPick = typeId == 13 && (action == 2)
      const partFullGifts = typeId == 28 && (action == 2)

      if (this.cartPromotionMap && this.cartPromotionMap[promotionId] && isAnalysis) {
        daEventCenter.triggerNotice({
          daId: '1-7-5-2',
          extraData: {
            id: promotionId,
            typeId,
            isFullPromotion: this.cartPromotionMap[promotionId].isFullPromotion,
            is_brand_product: promotionItem?.goods?.type,
            is_member_gift: 0,
            is_all_store: this.integrityPromotionInfo?.all_store_flag,
            store_id: this.integrityPromotionInfo?.store_id,
          }
        })
      }


      let type
      if (buyGiftPick) type = 'buy-gift' // 买赠
      if (addBuyPick) type = 'add-buy' // 加价购
      if (partFullGifts) type = 'full-gift' // 部分满赠
      
      if (type) {
        if (this.page == 'bag') {
          window.open('/cart', '_blank').location
        } else {
          this.openAddItem( { type, promotionId: promotionId })
        }
      } else if (addGoodToReach) {

        if([13, 2, 28, 9, 25, 1, 15, 20].includes(typeId) || (typeId == 14 && this.integrityPromotionInfo?.goods?.type != 3)) {
          if(this.page == 'bag') {
            window.location.href = `/cart?promotionId=${promotionId}`
            return
          }
          this.$emit('handleAddOn', {
            typeId,
            promotion_id: promotionItem?.promotion_id
          }, 'AddOnV2')
          return 
        }

        const newWin = window.open('', '_self')
        const url = await PromotionUtils.promotionLink(promotionItem)
        newWin.location = url
      } else if (this.showFlashSaleEnter) {
        const newWin = window.open('', '_self')
        newWin.location = `/flash-sale.html?mallCode=${promotionItem.mall_code}`
      }
    }
  }
}
</script>
<style lang="less">
.promo-group{
  display: flex;
  align-items: center;
  .cart_item-banner:not(.mini-cart){
    font-size: 16px;
    color: #000000;
    font-weight: bold;
  }
  .cart_item-banner,.cart-item__header-section{
    flex: 1;
    background: @sui_color_promo_bg;
    padding: 16px;
    .flexbox();
    .space-between();
    .cart_item-title {
      .flexbox();
      .space-between();
    }
    .cart_item-action {
      .padding-l(10px);
      position: static;
      white-space: nowrap;
    }
    .promotion-title {
      .text-overflow();
      width: 220px;
    }
    &.clickable {
      cursor: pointer;
    }
  }
  .cart-red-dark {
    color: @color_red_dark;
  }
}
/* stylelint-disable-next-line selector-class-pattern */
.cart_item-header__gift {
  display: block;
  width: 100%;
  .header-content {
    display: flex;
    align-items: center;
    width: 100%;
  }
}
</style>
