<template>
  <span 
    :class="needBgClip ? 'qw-num-box-clip' : 'qw-num-box' " 
    :style="{ height: height+'px', 'line-height': height+'px' }"
  > 
    <span
      ref="trasBox"
      class="tras-box"
      :class="[GB_cssRight ? 'tras-box-ar':'tras-box-normal', isDelay ? 'tras-box__delay' : 'tras-box__transition']"
      :style="!needBgClip ? { top:( -1 * value * height) +'px' } : {}"
    >
      <span 
        class="qwnum"
        :style="{ height: height+'px', 'line-height': height+'px' }"
      >0</span>
      <span
        v-for="i in 9"
        :key="i"
        :style="{ height: height+'px', 'line-height': height+'px' }"
      >{{ i }}</span><br />
    </span>
  </span>
</template>
<script>
const { GB_cssRight } = gbCommonInfo
export default {
  name: 'NumRun',
  props: {
    value: {
      type: Number,
      default() {
        return 0
      }
    },
    isDelay: {
      type: Boolean,
      default: false
    },
    height: {
      type: Number,
      default: 20
    },
    // 如果数字颜色是过渡色 需要传入true
    // 原来是用top控制数字位置，需要传入true 用margin-top去控制数字位置
    needBgClip: {
      type: Boolean,
      default: false
    },
    bgClipDelay: {
      type: Number,
      default: 0
    }

  },
  data(){
    return {
      GB_cssRight
    }
  },
  mounted() {
    if (this.needBgClip) {
      let timer = null
      if (this.bgClipDelay) {
        timer = setTimeout(() => {
          this.$refs.trasBox.style.marginTop = `${-1 * this.value * this.height}px`
          clearTimeout(timer)
        }, this.bgClipDelay)
      } else {
        timer = setTimeout(() => { // 修复PC动画不会执行（偶现）
          this.$refs.trasBox.style.marginTop = `${-1 * this.value * this.height}px`
          clearTimeout(timer)
        })
        // this.$nextTick(() => {
        //   this.$refs.trasBox.style.marginTop = `${-1 * this.value * this.height}px`
        // })
      }
    }
  },
}
</script>
<style lang="less" scoped>
/*postcss-shein:ignore*/
.qw-num-box {
  position: relative;
  display: inline-block;
  overflow: hidden;
  width: 8px;
  height: 20px;
  line-height: 20px;
  top: 4px;

  .tras-box {
    display: block;
    position: absolute;
    top: 0;
    height: auto;
    width: 100%;
    transform-origin: 0 0;
    transition: top 0.8s;
    span{
      display: block;
      height: 20px;
      line-height: 20px;
    }
    &.tras-box-ar{
      right:0;
    }
    &.tras-box-normal{
      left:0;
    }
  }
  .tras-box__transition {
    transition: top 0.8s;
  }
  .tras-box__delay {
    transition-delay: .8s;
  }
}

.qw-num-box-clip {
  // position: relative;
  display: inline-block;
  overflow: hidden;
  width: 8px;
  height: 20px;
  line-height: 20px;
  top: 4px;

  .tras-box {
    display: block;
    // position: absolute;
    top: 0;
    height: auto;
    width: 100%;
    transform-origin: 0 0;
    transition: all 1s;
    span{
      display: block;
      height: 20px;
      line-height: 20px;
    }
    &.tras-box-ar{
      right:0;
    }
    &.tras-box-normal{
      left:0;
    }
  }
  .tras-box__transition {
    transition: all 1s;
  }
  .tras-box__delay {
    transition-delay: .5s;
  }
}
</style>
