<template>
  <span style="direction: ltr; unicode-bidi: bidi-override">
    <template v-for="(i, j) in transformAmount">
      <num-run
        v-if="parseInt(i) >= 0"
        :key="j"
        :value="parseInt(i)"
        :style="{ width: boxWidth + 'px', top: boxTop + 'px' }"
        :height="boxHeight"
        :need-bg-clip="needBgClip"
        :bg-clip-delay="bgClipDelay"
        :is-delay="isDelay"
      />
      <span
        v-else
        :key="i+j"
      >{{ i }}</span>
    </template>
  </span>
</template>

<script>
import numRun from './numRun.vue'
const { GB_cssRight } = gbCommonInfo
export default {
  name: 'NumberRoll',
  components: {
    numRun,
  },
  props: {
    amount: {
      type: [String, Number],
      default() {
        return ''
      },
    },
    boxWidth: {
      type: Number,
      default() {
        return 8
      },
    },
    boxHeight: {
      type: Number,
      default: 20,
    },
    boxTop: {
      type: Number,
      default() {
        return GB_cssRight ? 4 : 5
      },
    },
    isDelay: {
      type: Boolean,
      default: false
    },
    needBgClip: {
      type: Boolean,
      default: false
    },
    bgClipDelay: {
      type: Number,
      default: 0
    }
  },
  computed: {
    transformAmount(){
      return (this.amount + '').split('')
    }
  }
}
</script>
